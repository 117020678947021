import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../../components/Breadcrumb.css'
import styled from "styled-components";
import media from "styled-media-query";

import Layout from "../../components/Layout";
import Facilities from "../../components/Facilities";

import MainImage1 from "../../img/nawate-2.jpg";
import MainImage2 from "../../img/nawate-3.jpg";
import BgImage1 from "../../img/news-bg.png";

const NawatePage = ({ pageContext }) => {
  const { breadcrumb: { crumbs }} = pageContext;

  return (
    <Layout>
      {/*
      パンくず
      メインビジュアル
      タイトル
      2カラム紹介
      2カラムボタン
      詳細施設案内
      Googleマップ埋め込み
      共通の施設案内
      パンくず
      */}
      <Body>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="NAWATE"  />
      <ImageBig src={MainImage1}></ImageBig>
      <MidashiBg>
        <Komidashi>ゲストハウスとお店、アトリエが入居している、<br />
          小さくも多様な人の集まる複合施設</Komidashi>
        <Midashi>NAWATE</Midashi>
      </MidashiBg>
      <p>
      ゲストハウスとお店、アトリエが中庭を囲むように入居している、<br />
      小さくも多様な人の集まる複合施設です。
      </p>
      <Intro2ColumnDiv>
          <Column>
              <ImageSmall src={MainImage2}></ImageSmall>
          </Column>
          <Column>
              <Komidashi>好きな活動を自由にのびのびと</Komidashi>
              <p>NAWATEはゲストハウスとりいくぐるを含む、小さな複合施設です。とりいをくぐると中庭を囲むように店々が並んでいます。入居者が自由にカスタマイズできる賃貸物件。自分なりの活動や表現をする人々があらたな取り組みをはじめやすい場所です。</p>
                 <br />
                 <br />
              <p>
              〈現在の入居者〉<br />
              Guesthouse&Loungeとりいくぐる(ゲストハウス)<br />
              One Scene (刺繍のオーダーショップ)<br />
              Bypass (個人アトリエ)<br />
              n＝1 (ギャラリー、貸しスペース)<br />
              YURURI (リラクゼーション)<br />
              </p>
          </Column>
      </Intro2ColumnDiv>
      <DetailDiv>
          <Nakamidashi>NAWATE</Nakamidashi>
          <br />
          <p>
            〒700-0026　岡山市北区奉還町4丁目 7-15<br />
          </p>
      </DetailDiv>
      <iframe src="https://www.google.com/maps?output=embed&z=16&ll=34.668877,133.9075778&q=とりいくぐる" width="100%" height="300px" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
      <br />
      </Body>
      <Facilities />
      <FooterDiv>
        <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="NAWATE"  />
      </FooterDiv>
    </Layout>
  );
}

export default NawatePage;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
  
`;

const MidashiBg = styled.div`
  width: 100%;
  background-image: url(${BgImage1});
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  text-align: center;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 10em;
    margin: 0 0 2em 0;
    padding: 2em 15vw 0 15vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 15em;
    margin: 0 0 5em 0;
    padding: 5em 15vw 0 15vw;
  `}
`;

const Intro2ColumnDiv = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
    margin: 5vh 0 0 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 12vh 0 0 0;
  `}
`;

const DetailDiv = styled.div`
  height: 28vh;
  background: #ffffff;
  border-radius: 10px;
  margin: 0 auto 5vh auto;
  padding: 3vh 3vw 3vh 3vw;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 50%;
  `}
`;

const FooterDiv = styled.div`
  margin: 3vh 5vw 3vh 5vw;
`;

const Column = styled.div`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    width: 90vw;
    margin: 0 0 10vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    width: 40vw;
  `}
`;

const ImageBig = styled.img`
  width: 100%;
  border-radius: 10px;
  margin: 0 0 3vh 0;
  object-fit: cover;
  ${media.lessThan("medium")`
  /* screen width is less than 768px (medium) */
  height: 30vh;
`}
${media.greaterThan("medium")`
  /* screen width is greater than 1170px (large) */
  height: 80vh;
`}
`;

const ImageSmall = styled.img`
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    height: 30vh;
    margin: 0 0 3vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    height: 50vh;
    margin: 0 0 10vh 0;
  `}
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 18pt;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 28pt;
  `}
`;

const Komidashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    font-size: 10pt;
    margin: 0 0 1vh 0;
  `}
  ${media.greaterThan("medium")`
    /* screen width is greater than 1170px (large) */
    font-size: 18pt;
    margin: 0 0 5vh 0;
  `}
`;

const Nakamidashi = styled.p`
font-family: 'Noto Sans JP', sans-serif;
font-weight: 700;
font-size: 14pt;
text-align: center;
`;
